<template>
<span class="ColorfulTitle">
    <div class="color-line" :style="`background: ${color}`"></div>
    <div class="title">
        <slot name="title">{{title}}</slot>
    </div>
    <el-tooltip :content="hint" :placement="hintPo" v-if="hint">
        <i class="iconfont icon-hint"/>
    </el-tooltip>
</span>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
        },
        hint: {
            type: String,
            default: ''
        },
        hintPo: {
            type: String,
            default: 'right'
        },
        color: {
            type: String,
            default: '#1D517E'
        }
    },
}
</script>

<style lang='scss'>
.ColorfulTitle {
    @include flex(row, flex-start, center);
    margin-top: 10px;
    .color-line {
        width: 4px;
        height: 16px;
        border-radius: 2px;
        margin-right: 10px;
    }
    .title {
        font-size: 16px;
        font-weight: 500;
        color: #323538;
        line-height: 22px;
    }
    i.icon-hint {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.45);
        margin-left: 8px;
    }
}
</style>